import React, { FunctionComponent } from 'react'
import SEO from '../components/seo'
import Layout from './layout'

const NotFoundPage: FunctionComponent = () => (
  <Layout>
    <main>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </main>
  </Layout>
)

export default NotFoundPage
